import {http} from './config';

export default {
	index(page) {
		return http.get(`/api/v1/indiceCorrecaoMonetaria?page=${page}`);
	},
	// create(data) {
	// 	return api.post('/monetary-correction-indexes', data);
	// },
	// update(data) {
	// 	return api.put(`/monetary-correction-indexes/${data.id}`, data);
	// },
	// delete(id) {
	// 	return api.delete(`/monetary-correction-indexes/${id}`);
	// },
};
